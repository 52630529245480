import axios from "axios";

const domain = "https://adminserverpickup.ivalleytraining.com/";
//const domain="https://localhost:5003/";

//const domain="http://38.242.227.244:3031";

//const domain="https://localhost:5002";
//const domain = 'https://server.ivalleytraining.com/';

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("AccessToken");

export default axios.create({
  baseURL: domain
  // headers: {
  //     Authorization: "Bearer " + localStorage.getItem("AccessToken")
  //   }
});
